import { useMutation, useQuery } from '@apollo/client'
import { useEffect, useState, useCallback } from 'react'
import upsertMicrositePermissionMutation from '../mutations/upsertPermission'
import getMicrositesPermissions from '../queries/getMicrositesPermissions'
import useToggle from '../../../hooks/useToggle'
import useToast, { ToastType } from '../../../hooks/useToast'
import deletePermissionMutation from '../mutations/deletePermission'

export interface CommunitiesMicrositePermission {
  id?: string
  firstName: string
  lastName: string
  emailAddress: string
  communityId: string
  expirationDate: Date
  token?: string
  shortUrl?: {
    targetUrl: string
  }
}

const DEFAULT_PAGINATION = {
  pageSize: 20,
  orderBy: 'createdAt',
  orderDir: 'DESC'
}
const useCommunitiesMicrositesPermissions = () => {
  const [pagination] = useState(DEFAULT_PAGINATION)
  const [query, setSearchQuery] = useState<string | undefined>()
  const [lastCursor, setLastCursor] = useState(null)
  const [micrositePermission, setMicrositePermission] = useState<
    CommunitiesMicrositePermission | undefined
  >()
  const [selectedForDeletion, setSelectedForDeletion] = useState<
    CommunitiesMicrositePermission | undefined
  >()
  const { isOn, turnOn, turnOff } = useToggle()
  const { showToast } = useToast()

  const [upsertMicrositePermission, { loading: loadingMutation }] = useMutation(
    upsertMicrositePermissionMutation
  )
  const [deleteMicrositePermission, { loading: loadingDeletion }] = useMutation(
    deletePermissionMutation
  )

  const { data, loading, refetch, fetchMore: fetchMorePermissions } = useQuery(
    getMicrositesPermissions,
    {
      variables: {
        query,
        pagination: {
          ...pagination
        }
      }
    }
  )

  const fetchMore = useCallback(() => {
    const cursor = data?.getMicrositesPermissions?.cursor

    if (!cursor || !data || loading || cursor === lastCursor) {
      return
    }

    fetchMorePermissions({
      variables: {
        query,
        pagination: {
          ...pagination,
          cursor: lastCursor || cursor
        }
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const typeKey = Object.keys(fetchMoreResult)[0]
        const newResults = fetchMoreResult?.[typeKey]?.results || []
        const newCursor = fetchMoreResult?.[typeKey]?.cursor || null

        const previousResults = prev?.[typeKey]?.results || []

        if (newResults.length === 0 || !newCursor) {
          setLastCursor(cursor)
          return prev
        }

        setLastCursor(newCursor)

        return {
          ...prev,
          [typeKey]: {
            ...prev?.[typeKey],
            results: [...previousResults, ...(newResults || [])]
          }
        }
      }
    })
  }, [fetchMorePermissions, data, lastCursor, loading, pagination, query])

  const handleSearch = useCallback(
    (query?: string) => {
      // Reset data and cursor
      setLastCursor(null)
      setSearchQuery(query)

      // Clear the results before refetching
      refetch({
        query,
        pagination: DEFAULT_PAGINATION
      })
    },
    [setSearchQuery, setLastCursor, refetch]
  )

  const micrositesPermissions = (
    data?.getMicrositesPermissions?.results ?? []
  ).map(msp => ({ ...msp, communityId: msp?.community?.id }))

  const findById = (selectedId: string) =>
    micrositesPermissions.find(({ id }) => id === selectedId)

  useEffect(() => {
    if (!isOn) {
      setMicrositePermission(undefined)
    }
  }, [isOn])

  useEffect(() => {
    if (!isOn && micrositePermission?.id) {
      turnOn()
    }
  }, [micrositePermission?.id])

  const selectMicrositePermission = (selectedId: string) => {
    setMicrositePermission(findById(selectedId))
  }

  const selectForDeletion = (selectedId: string) => {
    setSelectedForDeletion(findById(selectedId))
  }

  const cancelDeletion = () => setSelectedForDeletion(undefined)

  const confirmDeletion = () => {
    deleteMicrositePermission({
      variables: { id: selectedForDeletion?.id },
      onCompleted: () => {
        setSelectedForDeletion(undefined)
      },
      refetchQueries: [getMicrositesPermissions]
    })
  }

  const upsertMicrositePermissionWithVariables = (
    values: CommunitiesMicrositePermission
  ) => {
    upsertMicrositePermission({
      variables: {
        ...values
      },
      onCompleted: data => {
        if (data.inviteToCommunityMicrosite) {
          showToast(
            'Your Microsite Permission was generated',
            ToastType.INFO,
            3000
          )
          setMicrositePermission(undefined)
          turnOff()
        }
      },
      onError: error => {
        showToast(error.message, ToastType.ERROR, 8000)
        turnOff()
      },
      refetchQueries: [getMicrositesPermissions]
    })
  }
  return {
    confirmDeletion,
    cancelDeletion,
    selectForDeletion,
    selectedForDeletion,
    isOn,
    turnOff,
    turnOn,
    selectMicrositePermission,
    upsertMicrositePermission: upsertMicrositePermissionWithVariables,
    loadingData: loading,
    loadingUpsertion: loadingMutation,
    loadingDeletion,
    micrositesPermissions,
    micrositePermission,
    refetch,
    fetchMore,
    handleSearch
  }
}

export default useCommunitiesMicrositesPermissions
